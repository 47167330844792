<template>
    <div>
        <div class="valia-content">
            <Header />


            <div class="valia-progress">
                <v-progress-linear v-model="completition"
                                   bg-color="#d8e5d3" height="6"
                                   color="#F27324"></v-progress-linear>
            </div>
            <BgEscolhas v-if="step!=17 && step!=23" />
            <BgFim v-else-if="step==23" />
            <BgFinalizar v-else-if="step==17" />
            <v-container class="position-relative z-index-1 question-container">
                <div class="question-container">
                    <s00_inicio @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" :visible="step==0" number="0"></s00_inicio>
                    <s04_endereco @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" @back="goback" :visible="step==1"></s04_endereco>
                    <s23_sexo @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" @back="goback" :visible="step==2"></s23_sexo>
                    <s01_contribuicao @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" @back="goback" :visible="step==3"></s01_contribuicao>
                    <s07_rendimento_mensal @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" @back="goback" :visible="step==4"></s07_rendimento_mensal>
                    <s03_ppe @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" setpessoadata="false" @back="goback" :visible="step==5"></s03_ppe>
                    <s04_preencheranalise @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" v-bind:opcaoanalise="opcaoanalise" @back="goback" :visible="step==6" @skipquestionario="skipquestionario"></s04_preencheranalise>
                    <div class="fs-24px valia-item-description secondary-text vamos-descobrir-perfil" v-if="step == 7 || step == 8 || step == 9 || step == 10 || step == 11 || step == 12 || step == 13">
                        <!--<span class="fs-24px fw-600">4- </span>-->Vamos descobrir o seu perfil de investimento.
                    </div>
                    <s04_preencheranalise_1 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==7"></s04_preencheranalise_1>
                    <s04_preencheranalise_2 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==8"></s04_preencheranalise_2>
                    <s04_preencheranalise_3 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==9"></s04_preencheranalise_3>
                    <s04_preencheranalise_4 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==10"></s04_preencheranalise_4>
                    <s04_preencheranalise_5 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==11"></s04_preencheranalise_5>
                    <s04_preencheranalise_6 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==12"></s04_preencheranalise_6>
                    <s04_preencheranalise_7 v-bind:perfilInvestidor="perfilInvestidor" @next="gonext" @back="goback" @selectperfilquiz="selectperfilquiz" :visible="step==13"></s04_preencheranalise_7>
                    <s05_revise @next="gonext" v-bind:perfilInvestidor="perfilInvestidor" @back="goback" descontofolhaativo="false" :visible="step==14" v-bind:pessoa="pessoa" fluxo="convidadomaior18"></s05_revise>
                    <s06_termos @next="gonext" @back="goback" :visible="step==15" v-bind:pessoa="pessoa" fluxo="convidadomaior18"></s06_termos>
                    <s22_upload_documentos @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" :visible="step==16" number="16"></s22_upload_documentos>

                    <s07_quase_terminando @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" @back="goback" @concluir="concluir" @definirIdadeEBeneficiarios="definirIdadeEBeneficiarios" :visible="step==17"></s07_quase_terminando>
                    <s08_idade_beneficio @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" @back="goback" :visible="step==18" @cadastrarBeneficiarioNao="cadastrarBeneficiarioNao"></s08_idade_beneficio>
                    <s09_cadastrar_beneficiario @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" @back="goback" @cadastrarBeneficiarioNao="cadastrarBeneficiarioNao" :visible="step==19"></s09_cadastrar_beneficiario>
                    <s09_cadastrar_beneficiario_2 @next="gonext" v-bind:pessoa="pessoa" fluxo="convidadomaior18" @back="goback" :visible="step==20" number="20"></s09_cadastrar_beneficiario_2>
                    <s10_sms @next="gonext" @back="goback" v-bind:pessoa="pessoa" fluxo="convidadomaior18" :visible="step==21"></s10_sms>
                    <s10_sms_token @next="gonext" @back="goback" v-bind:pessoa="pessoa" fluxo="convidadomaior18" :visible="step==22"></s10_sms_token>
                    <s11_parabens @next="gonext" @back="goback" v-bind:pessoa="pessoa" fluxo="convidadomaior18" :visible="step==23" @savepessoa="savePessoa"></s11_parabens>
                    
                    <div class="d-flex justify-content-end align-items-center mr-4 mt-1">
                        <button @click="goback();" v-if="step>0 && step<23" class="button-outline-arrow">
                            <v-icon>expand_less</v-icon>
                        </button>
                    </div>
                    

                </div>
            </v-container>
        </div>
        <!-- Modal -->
        <div class="modal fade valia-modal" id="modalDesistirPreenchimentoQuestionario" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDesistirPreenchimentoQuestionarioLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="z-index:99 !important;">
                <div class="modal-content">

                    <div class="modal-body">
                        <div class="fs-24px color-primary fw-700 text-center">Atenção!</div>
                        <div class="mt-3">
                            <div class="fs-18px">
                                Desistindo agora, você será alocado no Perfil Base. Caso deseje futuramente mudar seu perfil de investimento, será necessário preencher seu API (Análise de Perfil de Investidor).
                            </div>
                        </div>
                        <div class="mt-5 d-flex flex-column align-items-center">
                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                <button class="button-primary-bg-primary w-100 modal-button" @click="skipquestionario()" data-bs-dismiss="modal">
                                    Desistir do preenchimento
                                </button>
                                <button type="button" class="btn fill-after-w-100 mt-3 w-100 modal-button color-gray" data-bs-dismiss="modal">
                                    Continuar a preencher
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Modal 2-->
        <div class="modal fade valia-modal" id="modalPreenchimentoQuestionarioDiferenteSugestao" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalPreenchimentoQuestionarioDiferenteSugestao" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="z-index:99 !important;">
                <div class="modal-content">

                    <div class="modal-body">
                        <div class="fs-24px color-primary fw-700 text-center">Atenção!</div>
                        <div class="mt-3">
                            <div class="fs-18px">
                                Identificamos que o perfil de investimento escolhido não é adequado ao resultado obtido através do questionário API - Análise de Perfil do Investidor. Confirma a alteração de perfil de investimento?
                            </div>
                        </div>
                        <div class="mt-5 d-flex flex-column align-items-center">
                            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                                <button class="button-primary-bg-primary w-100 modal-button" @click="gonext()" data-bs-dismiss="modal">
                                    Sim
                                </button>
                                <button type="button" class="btn fill-after-w-100 mt-3 w-100 modal-button" data-bs-dismiss="modal">
                                    Não
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

</template>

<script>

    import Header from '../components/Header.vue';
    import BgEscolhas from '../components/BgEscolhas.vue';
    import BgFim from '../components/BgFim.vue';
    import BgFinalizar from '../components/BgFinalizar.vue';
    import s00_inicio from './steps/s00-inicio.vue';
    import s01_contribuicao from './steps/s01-contribuicao.vue';
    import s03_ppe from './steps/s03-ppe.vue';
    import s04_preencheranalise from './steps/s04-preencheranalise.vue';
    import s04_preencheranalise_1 from './steps/s04-preencheranalise-1.vue';
    import s04_preencheranalise_2 from './steps/s04-preencheranalise-2.vue';
    import s04_preencheranalise_3 from './steps/s04-preencheranalise-3.vue';
    import s04_preencheranalise_4 from './steps/s04-preencheranalise-4.vue';
    import s04_preencheranalise_5 from './steps/s04-preencheranalise-5.vue';
    import s04_preencheranalise_6 from './steps/s04-preencheranalise-6.vue';
    import s04_preencheranalise_7 from './steps/s04-preencheranalise-7-resultado.vue';
    import s05_revise from './steps/s05-revise.vue';
    import s06_termos from './steps/s06-termos.vue';
    import s07_quase_terminando from './steps/s07-quase-terminando.vue';
    import s08_idade_beneficio from './steps/s08-idade-beneficio.vue';
    import s09_cadastrar_beneficiario from './steps/s09-cadastrar-beneficiario.vue';
    import s09_cadastrar_beneficiario_2 from './steps/s09-cadastrar-beneficiario-2.vue';
    import s10_sms from './steps/s10-sms.vue';
    import s10_sms_token from './steps/s10-sms-token.vue';
    import s11_parabens from './steps/s11-parabens.vue';
     
    import s04_endereco from './steps/s04-endereco.vue';
    import s07_rendimento_mensal from './steps/s07-rendimento-mensal.vue';
    import s23_sexo from './steps/s23-sexo.vue';

    import s22_upload_documentos from './steps/s22-upload-documentos.vue';
     
    import { mapState } from "vuex"; 
    import NotificationService from '../services/notifications/NotificationService'; 
    import Enumerable from "linq"; 

    import Profile from "../services/profile/Profile";
    import Common from "../services/common/Common";

    export default {
        name: 'app',
        components: {
            Header,
            BgEscolhas,
            BgFim,
            s00_inicio,
            s01_contribuicao,
            s03_ppe,
            s04_preencheranalise,
            s04_preencheranalise_1,
            s04_preencheranalise_2,
            s04_preencheranalise_3,
            s04_preencheranalise_4,
            s04_preencheranalise_5,
            s04_preencheranalise_6,
            s04_preencheranalise_7,
            s05_revise,
            s06_termos,
            s07_quase_terminando,
            s08_idade_beneficio,
            s09_cadastrar_beneficiario,
            s09_cadastrar_beneficiario_2,
            s10_sms,
            s10_sms_token,
            s11_parabens,

             
            s04_endereco,
            s07_rendimento_mensal,

            s23_sexo,
            s22_upload_documentos,
            BgFinalizar,

        },
        data: () => ({

            key: null,
            loading: false,
            step: -1,
            title: process.env.VUE_APP_TEST_TITLE,
            pessoa: null,
            grausDeParentesco: null,
            arquivosPedidosFiliacao: null,
            arquivosTermosAdesao: null,

            cpf: null,
            protocolo: null,


            perfilInvestidor: {},

            breadCrumb: [],
            opcaoanalise: null,

            protocoloadesaoSent: false,
            informacoesperfilinvestimentoSent: false,
            adesaopendenteanaliseSent: false,
            formapagamentoboletoSent: false,
            formapagamentofolhaSent: false,

        }),
        computed: {
            ...mapState(["questionario"]),
            ...mapState(["token"]),
            ...mapState(["pessoaCorporativo"]),
            
            ...mapState(["userIP"]),
            ...mapState(["grausParentesco"]),
            ...mapState(["parentesco"]),
            ...mapState(["smscode"]),
            ...mapState(["patrocinado"]),
            completition: {
                get() {
                    return 4.34 * this.step;
                }
            }
        },
        methods: {  
            savePessoa() {
                Common.putPessoa(this.pessoa.id, this.pessoa);
            },
            gonext() {

                var lStep = this.step + 1;
                switch (lStep) {
                    case 1: //inciar adesao
                        this.pessoa["participante-valia"].novoPortal = true;
                        this.pessoa["autoriza-notificacoes"] = true;
                        this.pessoa["autoriza-uso-dados"] = true;
                        this.pessoa["autoriza-compartilhamento-dados"] = true;
                        this.savePessoa();

                        var lparentesco = Enumerable.From(this.grausParentesco)
                            .Where("$.COD_GRADPC == '" + this.pessoa["participante-valia"].parentesco + "'").FirstOrDefault();
                        if (lparentesco === undefined || lparentesco == null) {
                            lparentesco = "PRÓPRIO(A)";
                        }
                        else {
                            lparentesco = lparentesco.DCR_GRADPC;
                        }
                        this.$store.commit("saveParentesco", lparentesco);
                        
                        if (!this.protocoloadesaoSent) {
                            var entryPoint = window.location.href;
                            NotificationService.protocoloadesao("convidadomaior18", this.pessoa, this.parentesco, entryPoint);
                            this.protocoloadesaoSent = true;
                        }
                        break;
                    case 2:  
                        this.savePessoa();
                        break;
                    case 3:  
                        this.savePessoa();
                        break;
                    case 5:  
                        this.savePessoa();
                        break;
                    case 6:  
                        this.savePessoa();
                        break;
                    case 14:  //terminou questionario
                        this.savePessoa();
                        break;
                    case 15:  //revise
                        //TODO: talvez seja melhor remover este save, visto que salvar� no proximo passo
                        this.savePessoa();
                        break;
                    case 16:  //Termos e Condi��es filia��o ABRAPP
                        this.savePessoa();
                        break;
                    case 21:  //Beneficiarios salvos
                        this.savePessoa();
                        break;
                    case 22:  //telefone atualizado
                        this.savePessoa();
                        break;


                    case 23:  //atualiza docs (chamado pela propria tela via emit)
                        //    this.savePessoa();


                        if (!this.informacoesperfilinvestimentoSent) {
                            NotificationService.informacoesperfilinvestimento("convidadomaior18", this.pessoa, this.userIP, this.questionario);
                            this.informacoesperfilinvestimentoSent = true;
                        }

                        if (!this.adesaopendenteanaliseSent) {
                            NotificationService.adesaopendenteanalise("convidadomaior18", this.pessoa, this.parentesco, this.userIP, this.smscode);
                            this.adesaopendenteanaliseSent = true;
                        }
                        break;
                    case 7:
                        this.opcaoanalise = true;
                        break;
                }

                this.breadCrumb.push(this.step);

                this.step++;
            },
            goback() {

                this.step = this.breadCrumb.pop();

            },
            
            concluir() {
                if (this.step == 17)
                    this.breadCrumb.push(this.step);

                this.step = 21;
            },
            definirIdadeEBeneficiarios() {
                if (this.step == 17)
                    this.breadCrumb.push(this.step);

                this.step = 18;
            },
            cadastrarBeneficiarioNao() { 
                if (this.step == 19 || this.step == 20)
                    this.breadCrumb.push(this.step);

                this.step = 21;
            },
            async doselectquiz(evt, questao, valor) { //
                await Profile.doselectquiz(evt, questao, valor, this.questionario, this.perfilInvestidor);
            },
            async skipquestionario() {
                this.perfilInvestidor = {};

                await Profile.skipquestionario(this.questionario, this.perfilInvestidor);
                this.pessoa["perfil-investidor"] = this.perfilInvestidor;

                this.opcaoanalise = false;

                //remover eventuais passos
                for (var i = 0; i < this.breadCrumb.length; i++) {

                    if (this.breadCrumb[i] >= 7 && this.breadCrumb[i] <= 13) {
                        this.breadCrumb.splice(i, 1);
                        i--;
                    }
                }

                if (this.step == 6)
                    this.breadCrumb.push(this.step);

                this.step = 14;
            }, 
            async selectperfilquiz(evt, perfilSelecionado, perfilSugerido) {
                await Profile.selectperfilquiz(perfilSelecionado, perfilSugerido, this.perfilInvestidor, this.questionario);
                this.pessoa["perfil-investidor"] = this.perfilInvestidor;
            }

        },
        async created() {
            this.key = this.$route.query.id;

            var r = Common.decodeCRUrl(this.key);
            this.cpf = r.cpf;
            this.protocolo = r.protocolo;


            Common.loadInitialData();
            this.pessoa = await Common.loadPessoa(this.cpf, this.protocolo);
            if (this.pessoa.detalhesDaAdesao == undefined || this.pessoa.detalhesDaAdesao == null)
                this.pessoa.detalhesDaAdesao = {};

            this.pessoa.detalhesDaAdesao.publicoExterno = false;
            Common.loadPessoaCorporativo(this.pessoa, true);
            this.perfilInvestidor = this.pessoa["perfil-investidor"];
            Common.loadPatrocinado(this.pessoa["participante-valia"].matricula);
            this.step = 0;
        }

    };


</script>



<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .question-container {
        display: inline-block;
        position: relative;
        /*height: 100vh;*/
    }


    .slide-up-enter-active {
        transition: all 0.25s ease-out;
    }

    .slide-up-leave-active {
        transition: all 0.25s ease-out;
    }

    .slide-up-enter-from, .slide-up-leave {
        opacity: 0;
        transform: translateY(230px);
    }

    .slide-up-leave-to, .slide-up-enter {
        opacity: 0;
        transform: translateY(-230px);
    }
</style>
